import React from 'react';
import moment from 'moment';

import { ExhibitionEnhanced } from '@src/types/Exhibition';
import * as S from './ExhibitionsList.S';
import { useContainers } from '@src/hooks/useContainers';
import { useI18next } from 'gatsby-plugin-react-i18next';

interface ExhibitionsListProps {
  exhibitions: ExhibitionEnhanced[];
}

export const ExhibitionsList: React.FC<ExhibitionsListProps> = ({
  exhibitions,
}) => {
  const { ExhibitionItemContainer } = useContainers();
  const { t } = useI18next();
  const getStartsTomorrow = (startDate: string) => {
    const momentStartDate = moment(moment(startDate).format('YYYY-MM-DD'));
    const tomorrow = moment(moment().format('YYYY-MM-DD')).add(1, 'day');
    return tomorrow.isSame(momentStartDate, 'day');
  };

  const getIsHappeningNow = (startDate: string, endDate: string) => {
    const momentStartDate = moment(moment(startDate).format('YYYY-MM-DD'));
    const momentEndDate = moment(
      moment(endDate || startDate).format('YYYY-MM-DD')
    );
    const today = moment(moment().format('YYYY-MM-DD'));
    return today.isBetween(momentStartDate, momentEndDate, null, '[]');
  };

  const isInThePast = (dateString: string) => {
    const momentDate = moment(moment(dateString).format('YYYY-MM-DD'));
    const today = moment(moment().format('YYYY-MM-DD'));
    return momentDate.isBefore(today);
  };

  const renderExhibitions = (exhibitions: ExhibitionEnhanced[]) => {
    return exhibitions.map((exhibition, index) => {
      const { startAt, endAt } = exhibition;
      let startsTomorrow = false;
      let isHappeningNow = false;
      const inPast = isInThePast(endAt || startAt);
      if (!inPast) {
        isHappeningNow = getIsHappeningNow(startAt, endAt);
      }
      if (!inPast && !isHappeningNow) {
        startsTomorrow = getStartsTomorrow(startAt);
      }
      return (
        <ExhibitionItemContainer
          startsTomorrow={startsTomorrow}
          isHappeningNow={isHappeningNow}
          exhibition={exhibition}
          key={exhibition.id}
        />
      );
    });
  };

  const currentExhibitions = exhibitions.filter(
    ({ endAt, startAt }) =>
      !isInThePast(endAt || startAt) && getIsHappeningNow(startAt, endAt)
  );

  const futureExhibitions = exhibitions.filter(
    ({ endAt, startAt }) =>
      !isInThePast(endAt || startAt) && !getIsHappeningNow(startAt, endAt)
  );

  const hasCurrentExhbitions = !!currentExhibitions.length;
  const hasFutureExhibitions = !!futureExhibitions.length;
  let currentAndFutureHeading = '';

  if (hasCurrentExhbitions || hasFutureExhibitions) {
    if (hasCurrentExhbitions && hasFutureExhibitions) {
      currentAndFutureHeading = t('CurrentAndUpcomingExhibitions');
    } else if (hasCurrentExhbitions) {
      currentAndFutureHeading = t('CurrentExhibitions');
    } else {
      currentAndFutureHeading = t('UpcomingExhibitions');
    }
  }

  const currentAndFutureExhibitions = [
    ...futureExhibitions,
    ...currentExhibitions,
  ];

  const pastExhibitions = exhibitions.filter(({ endAt, startAt }) =>
    isInThePast(endAt || startAt)
  );

  return (
    <S.ExhibitionList>
      {currentAndFutureExhibitions.length > 0 && (
        <>
          <S.Group>
            <S.GroupHeading>{currentAndFutureHeading}</S.GroupHeading>
            {renderExhibitions(currentAndFutureExhibitions)}
          </S.Group>
        </>
      )}
      {pastExhibitions.length > 0 && (
        <>
          <S.Group>
            <S.GroupHeading>{t('PastExhibitions')}</S.GroupHeading>
            {renderExhibitions(pastExhibitions)}
          </S.Group>
        </>
      )}
    </S.ExhibitionList>
  );
};

export default ExhibitionsList;
