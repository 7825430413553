import React from 'react';

import * as S from './Heading.S';

export const Heading: React.FC<
  React.ComponentProps<'h1'> & {
    isHidden?: boolean;
    align?: 'left' | 'center' | 'right';
  }
> = ({ children, className, isHidden = false, align = 'left' }) => (
  <S.H1 isHidden={isHidden} className={className} align={align}>
    {children}
  </S.H1>
);

export const Heading2: React.FC<
  React.ComponentProps<'h1'> & {
    align?: 'left' | 'center' | 'right';
  }
> = ({ children, className, align = 'left' }) => (
  <S.H2 className={className} align={align}>
    {children}
  </S.H2>
);
