import styled from 'styled-components';

export const H1 = styled.h1<{
  isHidden: boolean;
  align: 'left' | 'center' | 'right';
}>`
  font-size: 30px;
  margin-bottom: 14px;
  @media screen and (pointer: coarse) {
    font-size: 22px;
  }
  ${({ isHidden }) =>
    isHidden &&
    `
  position: absolute;
  left: -10000px;
  top: -100px;
  `}
  text-align: ${({ align }) => align}
`;

export const H2 = styled.h1<{
  align: 'left' | 'center' | 'right';
}>`
  font-size: 28px;
  margin-bottom: 14px;
  @media screen and (pointer: coarse) {
    font-size: 20px;
  }
  text-align: ${({ align }) => align};
`;
