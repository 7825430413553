import styled from 'styled-components';

import {
  Container as ExhibitionItemContainer,
  ExhibitionBodyWrapper,
  ExhibitionHeader,
} from '../ExhibitionItem/ExhibitionItem.S';

export const ExhibitionList = styled.div``;

export const Group = styled.div`
  &:not(:first-of-type) {
    margin-top: 40px;

    @media screen and (max-width: 959px) {
      margin-top: 100px;
    }
  }

  ${ExhibitionItemContainer}:not(:first-of-type) {
    ${ExhibitionHeader} {
      @media screen and (max-width: 959px) {
        &::before {
          content: '';
          display: block;
          width: 3px;
          height: 57px;
          background-color: #cecece;
          position: absolute;
          top: -56px;
          left: -3px;
        }
      }
    }
  }

  ${ExhibitionItemContainer}:not(:only-of-type) {
    ${ExhibitionHeader} {
      @media screen and (max-width: 959px) {
        &::after {
          content: '';
          display: block;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          position: absolute;
          bottom: 0px;
          left: -6px;
          background-color: #cecece;
        }
      }
    }
  }

  ${ExhibitionItemContainer}:last-of-type {
    ${ExhibitionBodyWrapper} {
      border-left-color: transparent !important;
    }
  }
  ${ExhibitionItemContainer}:first-of-type {
    ${ExhibitionHeader} {
      @media screen and (max-width: 959px) {
        border-left-color: transparent !important;
        padding-top: 0;
      }
    }
  }
  ${ExhibitionItemContainer}:only-child {
    @media screen and (max-width: 959px) {
      ${ExhibitionHeader} {
        &::after {
          display: none;
        }
      }
    }
  }
`;

export const GroupHeading = styled.h2`
  margin: 0 0 40px 18px;
  font-size: 24px;
  font-weight: 300;

  @media screen and (max-width: 959px) {
    text-align: center;
  }
`;
