import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { Shell } from '@components/Shell/Shell';
import { ExhibitionEnhanced } from '@src/types/Exhibition';
import ExhibitionsList from '@src/components/ExhibitionList/ExhibitionList';
import { SEO } from '@src/components/SEO/SEO';
import { Content } from '@src/components/Content/Content';
import { Heading } from '@src/components/Heading/Heading';

const ExhibitionsPage: React.FC<
  PageProps & {
    data: {
      api: {
        exhibitionsPublished: ExhibitionEnhanced[] | null;
      };
      site: {
        siteMetadata: {
          siteUrl: string;
        };
      };
    };
  }
> = ({
  data: {
    api: { exhibitionsPublished },
    site: {
      siteMetadata: { siteUrl },
    },
  },
  location,
}) => {
  const { t } = useI18next();
  if (!exhibitionsPublished) {
    return null;
  }
  return (
    <Shell>
      <SEO
        locationOrigin={siteUrl}
        locationPathname={location.pathname}
        title={t('ArtExhibitions')}
        description={t('ExhibitionsHTMLDescription')}
      />
      <Content>
        <Heading className="mt0" isHidden>
          {t('ArtExhibitionsSkane')}
        </Heading>
        <Container>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={9} md={7} lg={6} xl={7}>
              <ExhibitionsList exhibitions={exhibitionsPublished} />
            </Grid>
          </Grid>
        </Container>
      </Content>
    </Shell>
  );
};

export default ExhibitionsPage;

export const query = graphql`
  query ($language: String!) {
    api {
      exhibitionsPublished {
        id
        slug
        imagePath
        listItemImagePathSharp: imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              width: 90
              height: 90
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        svTitle
        enTitle
        svBody
        enBody
        svTimeDate
        enTimeDate
        published
        locationAnchorText
        locationAnchorLink
        exhibitionWebsiteAnchorText
        exhibitionWebsiteAnchorLink
        startAt
        endAt
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
