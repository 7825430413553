import React from 'react';
import { Helmet, useI18next } from 'gatsby-plugin-react-i18next';
import { PATH } from '@src/types/aliases';

export const SEO: React.FC<{
  title: string;
  description: string;
  openGraphImagePath?: PATH;
  openGraphImageWidth?: number;
  openGraphImageHeight?: number;
  openGraphType?: string;
  locationOrigin: string;
  locationPathname: string;
}> = ({
  title,
  description,
  openGraphImagePath,
  openGraphImageWidth,
  openGraphImageHeight,
  locationOrigin,
  locationPathname,
  openGraphType = 'website',
}) => {
  const { language } = useI18next();
  const languageToTerritory = {
    en: 'en_US',
    sv: 'sv_SE',
  };
  const currentLocale =
    languageToTerritory[language as keyof typeof languageToTerritory];

  const alternateLocales = Object.entries(languageToTerritory)
    .filter(([l]) => l !== language)
    .map(([_, alternateLocale]) => alternateLocale);
  return (
    <Helmet>
      <title>{`${title} | lenalotten.art`}</title>
      <meta name="description" content={description} />
      <meta property="og:site_name" content="www.lenalotten.art" />
      <meta property="og:type" content={openGraphType} />
      <meta
        property="og:url"
        content={`${locationOrigin}${locationPathname}`}
      />
      <meta property="og:title" content={`${title} | lenalotten.art`} />
      <meta property="og:locale" content={currentLocale} />
      {alternateLocales.map((alternateLocale) => (
        <meta
          key={alternateLocale}
          property="og:locale:alternate"
          content={alternateLocale}
        />
      ))}
      {openGraphImagePath && (
        <meta
          property="og:image"
          content={`${locationOrigin}${openGraphImagePath}`}
        />
      )}
      {openGraphImagePath && openGraphImageWidth && (
        <meta property="og:image:width" content={`${openGraphImageWidth}`} />
      )}
      {openGraphImagePath && openGraphImageHeight && (
        <meta property="og:image:height" content={`${openGraphImageHeight}`} />
      )}
    </Helmet>
  );
};
